import { createStore } from "vuex";
// import api from "../api/index";
// let $api = new api({});
export default createStore({
  state: {
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}, //用户
    userbeans: localStorage.getItem("userbeans") || "", //用户余额
    user_token: localStorage.getItem("user_token") || "", //token
    audio: localStorage.getItem("audio") || true, //声音
    lang: localStorage.getItem("language") ? localStorage.getItem("language") : "zh", //语言
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
      localStorage.setItem("language", lang);
      location.reload();
    },
    setAudio(state, e) {
      state.audio = e;
      localStorage.setItem("audio", e);
    },
    login(state, token) {
      state.user_token = token;
      localStorage.setItem("user_token", token);
    },
    user(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    userbeans(state, beans) {
      state.userbeans = beans;
      localStorage.setItem("userbeans", JSON.stringify(beans));
    },

    loginOut(state) {
      $api.get({
        url: "auth/loginout",
        success: (res) => {
          setTimeout(() => {
            state.user = {};
            state.user_token = "";
            state.userbeans = "";
            localStorage.removeItem("userbeans");
            localStorage.removeItem("user");
            localStorage.removeItem("user_token");
          }, 500);
        }
      });
    },
    cleanuser(state) {
      state.user = {};
      state.user_token = "";
      state.userbeans = "";
      localStorage.removeItem("userbeans");
      localStorage.removeItem("user");
      localStorage.removeItem("user_token");
    }
  },
  actions: {
    getUser(context) {
      let time = localStorage.getItem("getUser");
      if (time) {
        if ((Date.now() - time) / 1000 < 1) {
          return;
        }
      }
      localStorage.setItem("getUser", Date.now());
      $api.get({
        url: "auth/userinfo",
        success: (res) => {
          context.commit("user", res.data);
          context.commit("userbeans", res.data.bean);
        }
      });
    },
    getuserbeans(context) {
      $api.get({
        url: "auth/bean",
        success: (res) => {
          context.commit("userbeans", res.data);
        }
      });
    }
  },
  modules: {}
});
