<template>
  <div :class="scrollTop > 170 ? 'fixed_header' : ''" class="index_demo">
    <div class="top_nav">
      <div class="top_nav_left">
        <div class="nav_left_phone">
          <img alt="" src="../assets/header/dw.png">
          <view class="nav_left_address"> Xinan Xinzhan Chuangxin science and Technology Park, Xinzhan District, Anhui ,China    </view>
        </div>
        <div class="nav_left_phone">
          <img alt="" class="icon_phone" src="../assets/header/dh.png">
          <view class="nav_left_address">+86  13349293098 </view>
        </div>
<!--        <div class="nav_left_phone">-->
<!--          <img alt="" class="icon_phone" src="../assets/header/cz.png">-->
<!--          <view class="nav_left_address">12121212121212</view>-->
<!--        </div>-->
      </div>
      <div class="top_nav_right">
        <div class="nav_right_select">
          <img v-if="language=='英语'" alt="" src="../assets/header/english.png">
          <img v-if="language=='俄语'" alt="" src="../assets/header/russian.png">
          <img v-if="language=='西班牙语'" alt="" src="../assets/header/spanish.png">
          <el-dropdown>
            <div class="select_text">{{$tm(language)}}</div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="changeLanguage('en')">{{ $tm('英语') }}</el-dropdown-item>
                <el-dropdown-item @click="changeLanguage('russian')">{{$tm('俄语')}}</el-dropdown-item>
                <el-dropdown-item @click="changeLanguage('spanish')">{{$tm('西班牙语')}}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="nav_list" style="position:relative;">
      <div class="nav_list_left">
        <div class="text">
          <img src="@/assets/logo.png" alt="">
<!--          <span>KING SLEW BEARING</span>-->
        </div>

      </div>
                <span style="position:absolute;left:120px;font-size: 26px;color: green;font-weight: bold ">KING SLEWING BEARING</span>
      <div class="nav_list_center">
        <div v-for="(item,i) in  list" :key="i" :class="routeName==item.path?'active':''" class="nav_lists"
             @click="topath(item)">{{ item.name }}
        </div>
      </div>
      <div class="nav_list_right">
        <div class="list_right_icon">
          <img alt="" src="../assets/header/bird.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
export default {
  components: {},
  data() {
    return {
      active: 'Index',
      language_list: ['en', "zh"],
      scrollTop: '',
      routeName:''
    }

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch:{
    $route:function (n) {
      console.log(n)
      this.routeName=n.name
    },
  }

  ,
  computed:{
    language(){

      let aaa=i18n.global.locale.value
      console.log('监听语言',aaa)

      if(aaa=='en'){
        return '英语'
      }else if (aaa=='russian'){
        return  '俄语'
      }else if(aaa=='spanish'){
        return '西班牙语'
      }
      return '请选择语言'
    },

    list(){
      return [
        {
          path: 'Index',
          name:this.$tm('首页')
        },
        {
          path: 'Shop',
          name: this.$tm('产品')
        },
        {
          path: 'about',
          name:  this.$tm('联系我们')
        },
        {
          path: 'aboutus',
          name:  this.$tm('关于我们')
        },
        {
          path: 'dtlx',
          name:  this.$tm('新闻资讯')
        }
      ]
    }
  }
  ,
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(this.scrollTop, '滚动距离')
    },
    changeLanguage(lang) {
      // window.translate.changeLanguage(item)

      localStorage.setItem('language', lang)
      i18n.global.locale.value =lang

    },
    topath(item) {
      this.active = item.path;
      this.$router.push({name: item.path});
    },
  }
}
</script>
<style lang="scss">
.fixed_header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.index_demo {
  max-width: 3000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .top_nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    box-sizing: border-box;
    background-color: #0D123A;

    .top_nav_left {
      display: flex;
      align-items: center;
      grid-column-gap: 15px;
      // grid-row-gap: 20px;
      .nav_left_phone {
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          margin-right: 5px;
        }

        .icon_phone {
          width: 25px;
          height: 25px;
        }

        .nav_left_address {
          color: #fff;;
          //max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .top_nav_right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav_right_select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
        }

        .select_text {
          color: #fff;
        }
      }

      .nav_right_login {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        grid-column-gap: 10px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }

  .nav_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;

    .nav_list_left {
      width: 300px;
      height: 70px;
      display: flex;
      align-items: center;
      background-color: #FBA31C;
      -webkit-clip-path: polygon(0 0, 74% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 74% 0, 100% 100%, 0% 100%);

      .text {
        display: flex;
        align-items: flex-end;
        img{
          height: 60px;
          width: 60px;
          margin-left: 45px;
          margin-right: 10px;
        }
      }
    }

    .nav_list_center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      .nav_lists {
        min-width: 70px;
        display: flex;
        justify-content: center;
        margin: 0 20px;
        cursor: pointer;
      }

      .nav_lists:nth-child(1) {
        margin: 0;
      }

      .active {
        color: #FBA31D;
      }
    }

    .nav_list_right {
      width: 300px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FBA31C;
      -webkit-clip-path: polygon(26% 0, 100% 1%, 100% 100%, 0% 100%);
      clip-path: polygon(26% 0, 100% 1%, 100% 100%, 0% 100%);

      .list_right_icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .nav {
    font-size: 30px;
    margin: 0 20px;
  }
}
</style>
