import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);
import store from "./store/index";
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import { ElMessage } from 'element-plus'
import api from "./api/index";
import i18n from "./i18n";
// 弹出提醒
app.config.globalProperties.$message = ElMessage;


// Axios 请求接口
let $api = new api({});
app.config.globalProperties.$api = $api;

//ROUTER 路由
import router from "./route";
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  window.scrollTo(0, 0);
  next();
});


app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(i18n)

app.mount("#app");
