import Axios from "axios";
import Bus from "../api/bus.js";
import Store from "../store/index.js";
// import Message from "@/styles/util/message.js";

let host = process.env.VUE_APP_API_HOST;
if (host == "/") {
  host = window.location.protocol + "//" + window.location.host + "/api/";
}

let local = "zh-CN";
Axios.defaults.timeout = 30000;
Axios.interceptors.request.use(
  (config) => {
    config.headers.Lang = local;
    config.headers.Authorization = "Bearer " + localStorage.getItem("user_token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default class api {
  get(options) {
    options.method = "GET";
    options.url = host + options.url;
    this.request(options);
  }
  post(options) {
    options.method = "POST";
    options.url = host + options.url;
    this.request(options);
  }
  request(options) {
    let data = {
      url: options.url,
      data: options.data,
      method: options.method
    };
    if (options.method == "GET") {
      data.url +=
        "?" +
        Object.keys(data.data ? data.data : {})
          .map(function (key) {
            return key + "=" + data.data[key];
          })
          .join("&");
    }
    Axios(data)
      .then((res) => {
        if (res.data.code == 200000) {
          this.$message({ type: "error", text: res.data.message });
          return options.error(res.data);
        }
        return options.success(res.data);
      })
      .catch((error) => {
        if (!error.response) {
          return;
        }
        if (error.response.status == 401) {
          Store.commit("cleanuser");
          Bus.emit("open_login");
        }
        if (error.message.includes("timeout")) {
            this.$message({ type: "error", text: "请求超时" });
        } else if (error && error.response && error.response.status) {
          let errCode = {
            400: "错误请求",
            401: "未授权，请重新登录",
            403: "拒绝访问",
            404: "未找到该资源",
            405: "请求方法未允许",
            408: "请求超时",
            500: "服务器端出错",
            501: "网络未实现",
            502: "网络错误",
            503: "服务不可用",
            504: "网络超时"
          };
          this.$message({ type: "error", text: errCode[error.response.status] });
        }
        return options.error(error.response.data);
      });
  }
}
