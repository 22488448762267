const en={
    英语:'English',
    俄语:'Russian',
    西班牙语:'Spanish',
    首页:'Home',
    产品:'Products',
    联系我们:'Contact Us',
    新闻资讯:'News',
    输入您的邮箱:'Enter your email address',
    公司名称:'Company Name',
    地址:'address',
    合肥王回转轴承科技有限公司:'Hefei King Slewing Bearing Technology Co.,Ltd',
    安徽省合肥市嘉陵江路396号:'No.396,JiaLingJiang road,Hefei, Anhui ,China',
    适用品牌:'Applicable brands',
    适用机型:'Fits  Model',
    零件号:'Part number',
    基本参数:'Basic parameters',
    价格:'price',
    付款方式:'payment method',
    产品详细描述:'Product detailed description',
    请输入姓名:'Please enter your name',
    请输入您的手机号或邮箱号:'Please enter your phone number or email address',
    请输入您的需求:'Please enter your requirements',
    我们会在1到3个工作日内与您联系:'We will contact you within 1 to 3 working days',
    点击提交:'Click submit',
    姓名不能为空:'Name cannot be empty',
    联系方式不能为空:'Contact information cannot be empty',
    需求不能为空:'Requirement cannot be empty',
    操作成功:'Successfully sent',
    '手机号码或邮箱不合法，请重新输入！':'The phone number or email is invalid, please re-enter!',
    '今日提交次数已达限制！':'The number of submissions today has reached the limit!',
    公司简介:'Company Profile',
    简介内容:'Hefei king slewing Bearing Technology Co., Ltd. is a professional enterprise that produces and exports excavator slew bearings and hydraulic cylinder. The factory is located in Hefei, Anhui Province, China, near Shanghai Port, and goods can be conveniently transported to all parts of the world.\n' +
        'Our main products are excavator slewing bearings and hydraulic cylinders. Now we can produce over 1000 part numbers to match many famous excavator brands, such as Caterpillar,JCB, Hitachi, Komatsu,Kobelco, HYUNDAI,VOLVO,DOOSAN,LIEBHERR,DAEWOO,CASE,SUMITOMO,KATO, Kubota ,IHI, Yangma, Terex, Atlas, LIUGONG, SANY ,XCMG etc.\n' +
        'Our engineers have over 20 years of rich experience, and the purpose of providing high-quality products is to save you maintenance time and costs, which is the meaning of our existence.\n' +'So far, customers from over 90 countries and regions have chosen our products and gained their recognition, which is our greatest honor.',
    activityTitle:'So far, customers from over 90 countries and regions have chosen our products and gained their recognition, which is our greatest honor.',
    需要信息联系我们:'Need Information Contact Us',
    最近动态:'Recent updates',
    搜索:'search',
    请输入商品名:'Please enter the product name',
    请输入零件号:'Please enter the part number',
    关于我们:'About Us',
    中国安徽省新站区新安新站创新科技园:' Xinan Xinzhan Chuangxin science and Technology Park, Xinzhan District, Anhui ,China',
    产品描述:'Description'
}
export default en
