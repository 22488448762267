const spanish = {
    英语: 'Inglés',
    俄语: 'Ruso',
    西班牙语: 'Español',
    首页: 'Página de inicio',

    产品:'Productos',
    联系我们:'Contáctanos',
    新闻资讯:'Información de noticias',
    输入您的邮箱:'Introduzca su buzón',
    公司名称:'Nombre de la empresa',
    地址:'Dirección',
    合肥王回转轴承科技有限公司:'Hefei Wang Rotation bearing Technology co., Ltd.',
    安徽省合肥市嘉陵江路396号:'No. 396 jialingjiang road, ciudad de hefei, Provincia de Anhui',
    适用品牌:'Marca aplicable',
    适用机型:'Modelo aplicable',
    零件号:'Número de pieza',
    基本参数:'Parámetros básicos',
    价格:'Precio',
    付款方式:'Método de pago',
    产品详细描述:'Descripción detallada del producto',
    请输入姓名:'Por favor, introduzca su nombre.',
    请输入您的手机号或邮箱号:'Introduzca su número de teléfono móvil o número de correo electrónico.',
    请输入您的需求:'Por favor, introduzca sus necesidades',
    我们会在1到3个工作日内与您联系:'Nos pondremos en contacto con usted en 1 a 3 días hábiles.',
    点击提交:'Haga clic para enviar',
    姓名不能为空:'El nombre no puede estar vacío',
    联系方式不能为空:'Los datos de contacto no pueden estar vacíos',
    需求不能为空:'La demanda no puede estar vacía',
    操作成功:'Operación exitosa',
    '手机号码或邮箱不合法，请重新输入！':'¡El número de teléfono móvil o el buzón no son legales, ¡ por favor, vuelva a ingresar!',
    '今日提交次数已达限制！':'¡¡ el número de presentaciones de hoy ha alcanzado el límite!',
    公司简介:'Perfil de la empresa',
    简介内容:'Tecnología de rodamientos giratorios" es una empresa especializada en la producción y exportación de excavadoras, rodamientos giratorios y cilindros hidráulicos. La fábrica se encuentra en la ciudad de Hefei, provincia de Anhui, China, cerca del puerto de Shanghai, los productos pueden ser fácilmente enviados a todo el mundo. Nuestros productos principales son excavadoras, rodamientos giratorios y cilindros hidráulicos. Ahora podemos producir más de 1000 números de piezas para cumplir con muchas marcas conocidas de excavadoras, tales como Caterpillar, JCB, Hitachi, Komatsu, Shengang, Hyundai, Volvo, Doushan, Libheir, Daewoo, CASE, Sumitomo, Cato, Kubota, IHI, Yangma, Trecks, Atlas, Liugong, Santi Heavy Industries, XCMG, etc. D. Nuestros ingenieros tienen más de 20 años de experiencia en la entrega de productos de alta calidad para ahorrarle tiempo y gastos de servicio, y este es el sentido de nuestra existencia.',
    activityTitle:'Hasta la fecha, clientes de más de 90 países y regiones han elegido nuestros productos y han sido reconocidos, lo que es nuestro mayor honor.',
    需要信息联系我们:'Necesita información para contactarnos',
    最近动态:'Dinámica reciente',
    搜索:'Buscar',
    请输入商品名:'Por favor, introduzca el nombre del producto.',
    请输入零件号:'Introduzca el número de pieza',
    关于我们:'Sobre nosotros',
    中国安徽省新站区新安新站创新科技园:'Parque Científico y Tecnológico de innovación de la nueva estación de Xin \'an, Distrito de la nueva estación, Provincia de anhui, China',
    产品描述:'Explicación'
}
export default spanish
