<template>
  <div class="footer">
    <div class="footer_top">
      <div class="footer_top_title">{{ $tm('联系我们') }}</div>
      <div class="footer_top_search">
        <input v-model="email" :placeholder="$tm('输入您的邮箱')" type="text"/>
        <div class="search_submit" @click="submit">{{$tm('点击提交')}}</div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="footer_bottom_left">
        <div class="bottom_left_top">{{ $tm('公司名称') }}</div>
        <div class="bottom_left_center">{{ $tm('合肥王回转轴承科技有限公司') }}</div>
        <div class="bottom_left_bottom">
          <div class="left_bottom_top">{{ $tm('地址') }}</div>
          <div class="left_bottom_bottom">
            <!--                        <img src="../assets/footers/footer_icon.png" alt="">-->
            {{ $tm('中国安徽省新站区新安新站创新科技园') }}
          </div>
        </div>
      </div>
      <div class="footer_bottom_center">
        <div class="bottom_center_top">Userful Link</div>
        <div v-for="(item,index) in list" :key="index" class="bottom_center_info" @click="topath(item)">
          <div class="dian"></div>
          {{ item.name }}
        </div>
      </div>
      <div class="footer_bottom_right">
        <div class="bottom_right_top">Contact Information</div>
        <div class="bottom_right_list">
          <div class="right_list_icon">
            <img style="height: 26px;width: 26px" alt="" src="../assets/footers/fdh.png">
          </div>
          <div class="right_list_text">
            <text> +86  13349293098</text>
          </div>
        </div>
        <div class="bottom_right_list">
          <div class="right_list_icon" >
            <img style="height: 24px;width: 24px" alt="" src="../assets/footers/whatsapp.png">
          </div>
          <div class="right_list_text">
            <text> +86  13349293098</text>
          </div>
        </div>
        <div class="bottom_right_list">
          <div class="right_list_icon" >
            <img style="height: 24px;width: 24px" alt="" src="../assets/footers/telegram.png">
          </div>
          <div class="right_list_text">
            <text> +86  13349293098</text>
          </div>
        </div>
        <div class="bottom_right_list">
          <div class="right_list_icon" >
            <img style="height: 24px;width: 24px" alt="" src="../assets/footers/facebook.png">
          </div>
          <div class="right_list_text">
            <text>King008613349293098</text>
          </div>
        </div>
        <div class="bottom_right_list">
          <div class="right_list_icon" >
            <img style="height: 24px;width: 24px" alt="" src="../assets/footers/email.png">
          </div>
          <div class="right_list_text">
            <text>maohaiou65@gmail.com</text>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_info">
<!--      <div class="footer_info_text">Copyright © 2019. All Rights Reserved. 京ICP备1900dsds00号-1</div>-->
      <el-backtop :bottom="20" :right="20"/>
    </div>
  </div>
</template>
<script>
import {submitContactUs} from "@/api/other";

export default {
  components: {},
  data() {
    return {
      email: '',
      list_info: [
        {
          text: 'About'
        },
        {
          text: 'Categroy'
        },
        {
          text: 'LatestNews'
        },
        {
          text: 'About'
        },
        {
          text: 'About'
        },
      ]
    }

  },
  computed: {
    list() {
      return [
        {
          path: 'Index',
          name:this.$tm('首页')
        },
        {
          path: 'Shop',
          name: this.$tm('产品')
        },
        {
          path: 'about',
          name:  this.$tm('联系我们')
        },
        {
          path: 'aboutus',
          name:  this.$tm('关于我们')
        },
        {
          path: 'dtlx',
          name:  this.$tm('新闻资讯')
        }
      ]
    }
  }

  ,
  methods: {

    topath(item) {
      this.active = item.path;
      this.$router.push({name: item.path});
    },
    submit() {
      if (this.email == '') {
        this.$message.warning(this.$tm('联系方式不能为空'))
        return
      }


      let obj = {
        phoneOrEmail: this.email,
      }
      submitContactUs(obj).then(res => {
        if (res.code == 200) {

          this.$message.success(this.$tm(res.msg))
          this.reset()
        } else {

          this.$message.warning(this.$tm(res.msg))
        }

      })


    },
    reset() {
      this.email = ''

    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  max-width: 3000px;
  width: 100%;
  margin: 0 auto;
  // height: 600px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  background: url('../assets/footers/footer_bg.png') no-repeat center;
  background-size: 100% 100%;
  margin-top: 30px;
  padding: 30px;
  box-sizing: border-box;

  .footer_top {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .footer_top_title {
      font-size: 30px;
      color: #0D123A;
    }

    .footer_top_search {
      width: 600px;
      height: 60px;
      margin-top: 20px;
      background: #FFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      -webkit-clip-path: polygon(9% 0, 100% 0%, 92% 100%, 0% 100%);
      clip-path: polygon(9% 0, 100% 0%, 92% 100%, 0% 100%);

      input {
        width: 350px;
        outline: none;
        height: 60px;
        margin-left: 55px;
        border: none;
      }

      .search_submit {
        min-width: 190px;
        height: 60px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        background-color: #0D123A;
        color: #FFF;
        -webkit-clip-path: polygon(31% 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(31% 0, 100% 0, 100% 100%, 0% 100%);
        cursor: pointer;
      }
    }
  }

  .footer_bottom {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 100px;

    .footer_bottom_left {
      max-width: 40%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;

      .bottom_left_top {
        font-size: 30px;
        font-weight: bold;
        color: #FFF;
      }

      .bottom_left_center {
        color: #FFF;
        margin-top: 20px;
      }

      .bottom_left_bottom {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .left_bottom_top {
          font-size: 20px;
          color: white;
          font-weight: bold;
          margin: 20px 0;
        }

        .left_bottom_bottom {
          color: white;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
          }
        }
      }
    }

    .footer_bottom_center {
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;

      .bottom_center_top {
        color: #FFF;
        font-size: 20px;
        font-weight: bold;
      }

      .bottom_center_info {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: 10px;
        color: #FFF;
        font-size: 15px;
        cursor: pointer;

        .dian {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #FBA31D;
          margin-right: 10px;
        }
      }
    }

    .footer_bottom_right {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;

      .bottom_right_top {
        color: #FFF;
        font-size: 20px;
        font-weight: bold;
      }

      .bottom_right_list {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: 10px;

        .right_list_icon {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          //background-color: #FBA31D;

          img {
            width: 12px;
            height: 12px;
          }
        }

        .right_list_text {
          display: flex;
          justify-content: start;
          align-items: start;
          flex-direction: column;
          color: #FFF;
          font-size: 15px;
          margin-left: 10px;
        }
      }
    }
  }

  .footer_info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #262A4F;
    color: #FFF;
    padding-top: 20px;
    margin-top: 20px;
  }
}
</style>
