import axios from 'axios'

// 是否显示重新登录
export let isRelogin = { show: false };

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'http://192.168.1.11:8083',
  baseURL: 'https://98z37p2236.zicp.fun',
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {

    config.headers={
        // authorization:'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjNlNGY2MjMxLTVmMGEtNDBjZC1iMDE4LTYxZmM0Mjg1ZmIzNSJ9.D9TQoMlDFshk7_5W3iCpxnxndMF03F_6d0xB-b9KVwKEPTI6naIF_ScDTdDgvyJyjkT9DR4_jqCLrskPe6_0fw'
    }

  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
      return res.data
  },
  error => {
    console.log('err' + error)

    return Promise.reject(error)
  }
)

export default service
