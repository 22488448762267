import request from '@/utils/request'

// 提交联系我们
export const submitContactUs = (data) => {
    return request({
        url: '/web/contactUs/submit',
        method: 'post',
        data: data
    })

}
//获取动态列表
export const getActivityList = (data) => {
    return request({
        url: '/recent_activity/list',
        method: 'get',
        params: data
    })
}
