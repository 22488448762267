<template>
  <el-config-provider :locale="elLocale">
  <Headers></Headers>
  <router-view></router-view>
  <!-- <Index_home></Index_home> -->
   <Footers></Footers>
  </el-config-provider>
</template>

<script>
// import Index_home from './views/index.vue'
import Headers from "./components/header"; //顶部菜单
import Footers from "./components/footer";
import zh from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import i18n from "./i18n";

export default {
  name: 'App',
  components: {
    // Index_home,
    Headers,
    Footers
  },
  computed:{
    elLocale(){

      return i18n.global.locale.value === 'en' ? en : zh
    }
  }
}
</script>

<style>
   ::-webkit-scrollbar {
    display: none;
  }

   body{
     padding: 0;
     margin: 0;
   }
</style>
