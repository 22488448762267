
import {createI18n} from 'vue-i18n';

// 引入各个语言配置文件
import russian from './config/russian';
import en from './config/en';
import spanish from "@/i18n/config/spanish";
// 创建vue-i18n实例i18n
const i18n = createI18n({
    // 设置默认语言
    locale:localStorage.getItem('locale') || 'en', //语言标识
    globalInjection: true, // 全局注入,可以直接使用$t
    // 添加多语言(每一个语言标示对应一个语言文件)
    legacy: false,
    messages:{
        russian, //俄语
        en, //英文
        spanish,//西班牙
    }
})

export default i18n;
