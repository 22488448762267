const russian = {
    英语: 'Английский язык',
    俄语: 'Русский язык',
    西班牙语: 'Испанский язык',
    首页: 'Главная страница',

    产品:'Продукты',
    联系我们:'Свяжитесь с нами',
    新闻资讯:'Информация для прессы',
    输入您的邮箱:'Введите свой почтовый ящик',
    公司名称:'Название компании',
    地址:'Адрес',
    合肥王回转轴承科技有限公司:'Хэфэй Ван вращающийся подшипник',
    安徽省合肥市嘉陵江路396号:'улица Цзялинцзян, Хэфэй, провинция Аньхой, 396',
    适用品牌:'Применяемые марки',
    适用机型:'Тип самолета',
    零件号:'Номер запчасти',
    基本参数:'Основные параметры',
    价格:'Цены',
    付款方式:'Способы оплаты',
    产品详细描述:'Подробное описание продукции',
    请输入姓名:'Введите имя',
    请输入您的手机号或邮箱号:'Пожалуйста, введите номер телефона или почтового ящика.',
    请输入您的需求:'Пожалуйста, введите свои требования.',
    我们会在1到3个工作日内与您联系:'Мы свяжемся с вами в течение 1 - 3 рабочих дней.',
    点击提交:'Нажмите для представления',
    姓名不能为空:'Имя не может быть пустым.',
    联系方式不能为空:'Контакты не могут быть пустыми',
    需求不能为空:'Спрос не может быть пустым',
    操作成功:'Операция прошла успешно',
    '手机号码或邮箱不合法，请重新输入！':'Номер мобильного телефона или почтовый ящик незаконны, пожалуйста, введите его снова!',
    '今日提交次数已达限制！':'Сегодня количество заявок ограничено!',
    公司简介:'Краткая информация о компании',
    简介内容:'Технология поворотных подшипников» является специализированным предприятием по производству и экспорту экскаваторов, вращающихся подшипников и гидравлических цилиндров. Завод расположен в городе Хэфэй, провинция Аньхой, Китай, недалеко от порта Шанхая, товары могут быть легко отправлены по всему миру. Наши основные продукты - экскаваторы, вращающиеся подшипники и гидравлические цилиндры. Теперь мы можем производить более 1000 номеров деталей, чтобы соответствовать многим известным брендам экскаваторов, таким как Caterpillar, JCB, Hitachi, Komatsu, Shengang, Hyundai, Volvo, Doushan, Libheir, Daewoo, CASE, Sumitomo, Cato, Kubota, IHI, Yangma, Trecks, Atlas, Liugong, Santi Heavy Industries, XCMG и т. Д. Наши инженеры имеют более чем 20 - летний опыт предоставления высококачественных продуктов, чтобы сэкономить вам время и затраты на обслуживание, и это смысл нашего существования.',
    activityTitle:'На сегодняшний день клиенты из более чем 90 стран и регионов выбрали нашу продукцию и получили ее признание, что является нашей самой большой честью.',
    需要信息联系我们:'Нужна информация, чтобы связаться с нами.',
    最近动态:'Последние события',
    搜索:'Поиск',
    请输入商品名:'Введите название товара',
    请输入零件号:'Пожалуйста, введите номер запчасти.',
    关于我们:'О нас',
    中国安徽省新站区新安新站创新科技园:'Инновационный научно - технический парк Синьань, район Синьань, провинция Аньхой, Китай',
    产品描述:'Примечания'
}
export default russian
